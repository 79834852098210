import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import StickyBox from "react-sticky-box";
import { useInView } from "react-intersection-observer";
import borderRight from "../../../public/assets/images/home/borderRight.webp";
import { FEATURE_LIST } from "../../../data/StaticList";
import Image from "next/image";

export default function FeatureScroll() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Create an array of useInView hooks for each feature
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inViewRefs = FEATURE_LIST.map(() => useInView());

  useEffect(() => {
    // Check which feature is in view
    inViewRefs.forEach((inViewRef, index) => {
      if (inViewRef.inView) {
        setSelectedIndex(index);
      }
    });
  }, [inViewRefs]);

  return (
    <Grid
      sx={{
        display: { xs: "none", md: "flex" },
        alignItems: "flex-start",
        maxWidth: "1440px",
        margin: "auto",
      }}
    >
      <StickyBox
        style={{
          padding: "85px 0px 50px 50px",
          width: "60%",
        }}
      >
        <Grid
          width="100%"
          sx={{ padding: "50px 0px 50px 50px", height: "100%" }}
        >
          <Grid
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: { xs: "25px", md: "40px" } }}
              style={{
                margin: "5px 0px 36px 0",
                letterSpacing: "-1px",
                wordSpacing: "-1px",
                fontWeight: "700",
              }}
            >
              Unlock extra income for all your needs
            </Typography>
            {FEATURE_LIST.map((item, i) => {
              return (
                <Grid
                  key={i}
                  id={selectedIndex === i ? "bgShade" : "bgShadeHover"}
                  ref={inViewRefs[i].ref}
                >
                  <Image
                    src={item.sideIcon}
                    style={{
                      color: "green",
                      height: "24px",
                      width: "24px",
                      margin: "0px 10px",
                    }}
                    alt={item.alt}
                    loading="lazy"
                  />
                  <Grid
                    style={{
                      margin: "0px 10px 0 0",
                      padding: "0 10px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "18px" }}
                      id={
                        selectedIndex === i
                          ? "featureTitleSelected"
                          : "featureTitle"
                      }
                    >
                      {item.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      fontWeight={500}
                      id={
                        selectedIndex === i
                          ? "featureContSelected"
                          : "featureCont"
                      }
                    >
                      {item.content}
                    </Typography>
                    <Grid
                      sx={{
                        height: "auto",
                        width: "94px",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "white",
                        zIndex: 2,
                        display: selectedIndex === i ? "block" : "none",
                      }}
                    >
                      <Image
                        src={borderRight}
                        alt="borderRIghtImg"
                        loading="lazy"
                        width={24}
                        height={24}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </StickyBox>
      <div style={{ height: "fit-content" }}>
        <Grid
          sx={{
            margin: "auto",
          }}
        >
          {/* RIGHT_CARD */}
          {FEATURE_LIST.map((item, i) => (
            <Grid
              sx={{
                height: { xs: "310px", md: "414px" },
                width: { xs: "310px", md: "414px" },
                display: { xs: "none", md: "flex" },
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "end",
                fontSize: "28px",
                objectFit: "cover",
                margin: i === 3 ? "350px 0 0px 0" : "350px 0",
              }}
              key={i}
              ref={inViewRefs[i].ref}
            >
              <Image src={item.staticIcon} alt={item.alt} loading="lazy" />
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
}
